import React from 'react';
// Custom components
import PowerUpScreen from './Screens/PowerUpScreen/PowerUpScreen';
import HomeScreen from './Screens/HomeScreen/HomeScreen';
import TikTok from './Screens/TikTok/TikTok';

const DeviceExperience = (props) => {
  const { phoneState, appLaunch } = props;
  const state = phoneState;
  // console.log(phoneState);
  // console.log(appLaunch);

  if (state === 'PowerOn') {
    return <PowerUpScreen />;
  }

  if (state === 'Home') {
    return <HomeScreen callback={appLaunch} />;
  }
  if (state === 'PowerOff') {
    return;
  }
  if (state === 'TikTok') {
    return <TikTok />;
  }
  // TODO implement a smart way to do navigating more like an emulator
  // if (state === 'LoadApp') {
  //   return <App />
  // }
  // return;
};

export default DeviceExperience;
