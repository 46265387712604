import React from 'react';
import styles from './pulser.module.css'; // Import CSS module

const Pulser = ({ config }) => {
  const { state } = config;
  let color = '';

  // Set color based on state
  switch (state) {
    case 'STABLE':
      color = styles.green;
      break;
    case 'WARNING':
      color = styles.yellow;
      break;
    case 'DOWN':
      color = styles.red;
      break;
    default:
      color = styles.black;
  }

  const pulseClass = state === 'DOWN' ? styles.pulser : '';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        className={`${styles.circle} ${color} ${pulseClass}`}
        style={{ marginLeft: '4px' }}
      />
    </div>
  );
};

export default Pulser;
