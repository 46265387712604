import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './assets/theme/theme';
// import './assets/styles/index.css';
import { store } from './services/store';
import { Provider as StateProvider } from 'react-redux';
import ErrorBoundary from './error-boundary/ErrorBoundary';
import { isProduction } from './helpers/environment';
import { ScreenSizeProvider } from './providers/ScreenSizeProvider';
import App from './components/App';

if (isProduction()) {
  console.log = function () {};
} else {
  console.log('DEVELOPER MODE ENABLED');
}

const root = ReactDOM.createRoot(document.getElementById('root'));

// TODO need a theme provider
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <StateProvider store={store}>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <ScreenSizeProvider>
            <App />
          </ScreenSizeProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </StateProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
