import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import config from '../constants/constants';

// Create a context to hold the screen size
const ScreenSizeContext = createContext();

const ScreenSizeProvider = ({ children }) => {
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const [currentScreenSize, setCurrentScreenSize] = useState(null);

  useEffect(() => {
    if (isBigScreen) {
      setCurrentScreenSize(config.BIG_SCREEN);
    } else if (isDesktopOrLaptop) {
      setCurrentScreenSize(config.DESKTOP_SCREEN);
    } else if (isTabletOrMobile) {
      setCurrentScreenSize(config.MOBILE_SCREEN);
    }
  }, [isBigScreen, isDesktopOrLaptop, isTabletOrMobile]);

  return (
    <ScreenSizeContext.Provider value={currentScreenSize}>
      {children}
    </ScreenSizeContext.Provider>
  );
};

// Custom hook to access the screen size from any child component
const useScreenSize = () => {
  const screenSize = useContext(ScreenSizeContext);
  if (!screenSize) {
    throw new Error('useScreenSize must be used within a ScreenSizeProvider');
  }
  return screenSize;
};

export { ScreenSizeProvider, useScreenSize };
