import EasySaveImg from '../../assets/images/EasySave.png';
import PasswordGeneratorImg from '../../assets/images/Password-Generator.png';
import MonstersImg from '../../assets/images/Monsters.png';
import JokesSocialImg from '../../assets/images/Jokes-Social.png';
import TikTokImg from '../../assets/images/TikTok.png';
import PostaImg from '../../assets/images/Posta.png';
import BurgerBuilderImg from '../../assets/images/Burger-Builder.png';
import ReactReduxCartImg from '../../assets/images/React-Redux-Cart.png';
import PhotoSpreadImg from '../../assets/images/Photo-Spread.png';
import TicTacToeImg from '../../assets/images/Tic-Tac-Toe.png';

import PasswordGenerator from './PasswordGenerator/PasswordGenerator';
import EasySave from './EasySave/EasySave';
// import Monsters from './Monsters/Monsters';
import JokesSocial from './JokesSocial/JokesSocial';
import TikTok from './TikTok/TikTok';
// import Posta from './Posta/Posta';
import BurgerBuilder from './BurgerBuilder/BurgerBuilder';
import ReactReduxCart from './ReactReduxCart/ReactReduxCart';
// import PhotoSpread from './PhotoSpread/PhotoSpread';
import TicTacToe from './TicTacToe/TicTacToe';

const Posta = 'Posta';

const projectConfig = [
  {
    id: 1,
    title: 'Password Generator',
    caption: 'React',
    image: PasswordGeneratorImg,
    body: 'A client side, complete solution password generator, I have built this project both Frameworkless with VanillaJS and jQuery and with React. All code can be found on my github.',
    url: null,
    footer: '',
    height: 300,
    hasAction: true,
    status: 'STABLE',
    status_reason: 'ACTIVE',
    component: <PasswordGenerator />,
  },
  {
    id: 2,
    title: 'EasySave',
    caption: 'React',
    image: EasySaveImg,
    body: 'A client side, complete solution budget planner tool, This project has been built with react. Take a look here or launch the host below.',
    url: 'https://robertstoelhorst.github.io/easy-save/',
    footer: '',
    height: 300,
    hasAction: true,
    status: 'STABLE',
    status_reason: 'ACTIVE',
    component: <EasySave />,
  },
  {
    id: 3,
    title: 'TikTok',
    caption: 'React / Firebase App & Backend',
    image: TikTokImg,
    body: 'A Firebase hosted React application designed to simulate the TikTok UI, I had a lot of fun and got creative with CSS on this project. My device skin is built purely with CSS!',
    url: 'https://tik-tok-9b0d5.web.app/',
    footer: '',
    height: 300,
    hasAction: false,
    status: 'WARNING',
    status_reason: 'NETWORK_ERROR',
    component: <TikTok />,
  },
  {
    id: 4,
    title: 'Jokes-Social',
    caption: 'Ruby on Rails / Heroku App & Backend',
    image: JokesSocialImg,
    body: 'A fullstack project on heroku, I built this on my second project week at General Assembly. Users can Authenticate, Create joke posts, Like others jokes and generate random jokes from a public API.',
    url: 'https://murmuring-basin-92750.herokuapp.com/',
    footer: '',
    height: 300,
    hasAction: false,
    status: 'DOWN',
    status_reason: 'APPLICATION_ERROR',
    component: <JokesSocial />,
  },
  {
    id: 5,
    title: 'Monsters',
    caption: 'React',
    image: MonstersImg,
    body: 'This project is used as a playground, currently fetching data from a public API and something I continue to work on when I want to implement new concepts, take a look at my source code and approach to both class and functional components.',
    url: 'https://github.com/RobertStoelhorst/monsters-rolodex',
    footer: '',
    height: 300,
    hasAction: false,
    status: 'WARNING',
    status_reason: 'NETWORK_ERROR',
    component: null,
  },
  {
    id: 6,
    title: 'Posta',
    caption: 'PHP Laravel / Postgresql DB (local)',
    image: PostaImg,
    body: 'A fullstack app with local Postgresql DB. Users can register, authenticate and create posts in this social media functionality based application. I continue to learn more of the Laravel Framework.',
    url: null,
    footer: '',
    height: 300,
    hasAction: true,
    status: 'WARNING',
    status_reason: 'NO_HOST',
    component: <Posta />,
  },
  {
    id: 7,
    title: 'Burger Builder',
    caption: 'React / Firebase App & Backend',
    image: BurgerBuilderImg,
    body: 'A low level fullstack React app with firebase backend and app hosting, Users can create there custom burgers and place orders from the interface.',
    url: 'https://react-burger-builder-8135b.web.app/',
    footer: '',
    height: 300,
    hasAction: false,
    status: 'WARNING',
    status_reason: 'NETWORK_ERROR',
    component: <BurgerBuilder />,
  },
  {
    id: 8,
    title: 'React Redux Shopping',
    caption: 'React / Redux / Firebase App & Backend',
    image: ReactReduxCartImg,
    body: 'I have worked on a number of projects that use Redux tools and this was where I started learning the basics and can showcase the great power of Redux!',
    url: 'https://backend-cart-api.web.app/',
    footer: '',
    height: 300,
    hasAction: false,
    status: 'WARNING',
    status_reason: 'NETWORK_ERROR',
    component: <ReactReduxCart />,
  },
  {
    id: 9,
    title: 'Photo Spread',
    caption: 'Ruby / ThreeJS / Firebase App & Backend',
    image: PhotoSpreadImg,
    body: "A collaborative effort from General Assembly's group project week. As a 3 person team we decided to mix current knowledge with the unknown, With the ThreeJS library in mind we built PhotoSpread a fullstack media web app",
    url: 'https://photo-spread.herokuapp.com/',
    footer: '',
    height: 300,
    hasAction: false,
    status: 'DOWN',
    status_reason: 'APPLICATION_ERROR',
    component: null,
  },
  {
    id: 10,
    title: 'Tic Tac Toe',
    caption: 'Vanilla JS',
    image: TicTacToeImg,
    body: 'No description needed... LOL',
    footer: '',
    height: 300,
    hasAction: true,
    status: 'STABLE',
    status_reason: 'ACTIVE',
    component: <TicTacToe />,
  },
];

export default projectConfig;
