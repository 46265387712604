import React from 'react';
// MUI components
import {
  Fade,
  Grid,
  // eslint-disable-next-line no-unused-vars
  Button,
  TextField,
  InputAdornment,
  ListItemButton,
  // eslint-disable-next-line no-unused-vars
  ListItemIcon,
  // eslint-disable-next-line no-unused-vars
  Link,
} from '@mui/material';
// Device software config
import config from './softwareConfig';
// css
import Google from '../../../../../../assets/images/google-48.png';
import GoogleMic from '../../../../../../assets/images/google-mic-48.png';
import classes from '../../screensLayout.module.css';
import { useState } from 'react';

const HomeScreen = (props) => {
  const { callback } = props;
  const [googleSeachEngine, setGoogleSeachEngine] = useState('');
  // console.log(callback);
  // const appClickHandler = () => {};

  const googleSeachEngineSearch = () => {
    // If you wanna go crazy here you could do something with an i-frame to inject the results into the device window ??
    let query = googleSeachEngine;
    query = query.split('');
    if (
      query[0] === 'h' &&
      query[1] === 't' &&
      query[2] === 't' &&
      query[3] === 'p'
    ) {
      query = query.join('');
      window.open(query);
    }
    if (query[0] === 'w' && query[1] === 'w' && query[2] === 'w') {
      query.unshift('https://');
      query = query.join('');
      window.open(query);
    } else {
      query = query.join('');
      window.open(`https://www.google.com/search?q=${query}`);
    }
  };

  return (
    <Fade in={true} timeout={3300}>
      <div className={classes.screen_content_layout_home}>
        <Grid
          container
          spacing={0}
          // gap={1}
          justifyContent="center"
          alignContent="space-evenly"
        >
          {config.map((app) => {
            return (
              <ListItemButton
                key={app.id}
                onClick={() => {
                  if (app.hasJourney) {
                    // console.log(`${app.title} has Journey'`, app.hasJourney);
                    callback(app.title);
                  } else {
                    // console.log(`${app.title} has Journey'`, app.hasJourney);
                  }
                }}
                sx={{
                  flexDirection: 'column',
                  background: 'white',
                  padding: '5px 1px',
                  margin: '3%',
                  border: '2px solid whitesmoke',
                  borderRadius: '30%',
                  boxShadow: '1px 0 5px 1px rgb(226, 226, 226)',
                  '&:hover': {
                    backgroundColor: 'rgb(226, 226, 226)',
                    opacity: 0.9,
                  },
                }}
              >
                <img src={app.icon} alt={app.title} />
              </ListItemButton>
            );
          })}
          <TextField
            id="input-with-icon-textfield"
            fullWidth
            placeholder="Search Google or URL"
            onChange={(newValue) => {
              setGoogleSeachEngine(newValue.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                googleSeachEngineSearch();
              }
            }}
            InputProps={{
              disableUnderline: true,
              style: { fontSize: 20, color: '#454545' },
              startAdornment: (
                <InputAdornment position="start" sx={{ marginLeft: '0.1em' }}>
                  <img src={Google} height="32" alt="Google search icon" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ marginRight: '0' }}>
                  <img
                    src={GoogleMic}
                    height="32"
                    alt="Google search microphone icon"
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            sx={{
              margin: '0 3%',
              border: 'unset',
              fontSize: '50px',
              borderTopLeftRadius: '30px',
              borderBottomLeftRadius: '30px',
              borderTopRightRadius: '30px',
              borderBottomRightRadius: '30px',
              background: 'white',
              padding: 0.7,
            }}
          />
        </Grid>
      </div>
    </Fade>
  );
};

export default HomeScreen;
