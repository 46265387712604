import React from 'react';

const BurgerBuilder = () => {
  return (
    <div>
      <h1>BurgerBuilder</h1>
      <h1>BurgerBuilder</h1>
      <h1>BurgerBuilder</h1>
      <p>
        please head to{' '}
        <a href="https://placeholder.com/">www.BurgerBuilder.com</a>
      </p>
    </div>
  );
};

export default BurgerBuilder;
