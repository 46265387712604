import React from 'react';
// Custom components
import DeviceStatusBar from '../DeviceStatusBar/DeviceStatusBar';
import DeviceNavigation from '../DeviceNavigation/DeviceNavigation';
// Custom module styles
import classes from './deviceSkin.module.css';

const DeviceSkin = (props) => {
  const { children, state } = props;
  // console.log(children);
  // console.log(state);

  return (
    <div className={classes.device_skin}>
      <div className={classes.device_content}>
        <DeviceStatusBar props={state} />
        {children}
        <DeviceNavigation props={state} />
      </div>
    </div>
  );
};

export default DeviceSkin;
