import React, { useContext, useEffect, useState } from 'react';
import { IconButton } from '@mui/material/';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';

const Arrow = ({ children, disabled, onClick }) => {
  // console.log(children);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <IconButton
        aria-label="code snippet"
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </IconButton>
    </div>
  );
};

const LeftArrow = () => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete,
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <ArrowBackIos color="primary" />
    </Arrow>
  );
};

const RightArrow = () => {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } =
    useContext(VisibilityContext);

  // console.log({ isLastItemVisible });
  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <ArrowForwardIos color="primary" />
    </Arrow>
  );
};

export { LeftArrow, RightArrow };
