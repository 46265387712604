import React from 'react';
import Pulser from './Type/pulser';

const StatusIndicator = ({ config }) => {
  switch (config.type) {
    case 'pulser':
      return <Pulser config={config} />;
    default:
      return null; // or some default component if type doesn't match
  }
};

export default StatusIndicator;
