import React from 'react';
// MUI components
import { Typography, Button } from '@mui/material/';
import classes from './card.module.css';
import StatusIndicator from '../StatusIndicator/StatusIndicator';

const CarouselCard = ({ itemId, selected, onClick, title, content, size }) => {
  return (
    <div
      className={`${classes.card} ${classes[size]}`}
      onClick={() => {
        onClick();
      }}
      tabIndex={0}
    >
      <img
        src={content.image}
        style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
        alt="project screenshot"
        height=""
        width="100%"
      />

      <div className={classes.container}>
        <div
          style={{
            flexGrow: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          <Typography
            variant={`${
              size === 'sm'
                ? 'h6'
                : size === 'lg'
                ? 'h5'
                : size === 'xl'
                ? 'h4'
                : null
            }`}
            color="#40474c"
            sx={{ fontWeight: 600 }}
          >
            {title}
          </Typography>
          <Typography
            variant={`${
              size === 'sm'
                ? 'subtitle1'
                : size === 'lg'
                ? 'h6'
                : size === 'xl'
                ? 'h4'
                : null
            }`}
            color="secondary"
          >
            {content.caption}
          </Typography>
          <Typography
            variant={`${
              size === 'sm'
                ? 'body2'
                : size === 'lg'
                ? 'body1'
                : size === 'xl'
                ? 'body1'
                : null
            }`}
            color="#40474c"
            sx={{
              ml: 1,
              mr: 1,
            }}
          >
            {content.body}
          </Typography>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            // height: 'auto',
            flexGrow: 1,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '100%', display: 'flex', flexGrow: '1' }} />
          {/* Empty flexbox item to push button to center */}
          {content.url ? (
            <Button
              variant="outlined"
              color="success"
              size="small"
              style={{
                width: '100%',
                minWidth: '130px',
                display: 'flex',
                flexGrow: '1',
                zIndex: 9999,
              }}
              href={content.url}
              target="blank"
              rel="noreferrer"
            >
              Launch Host
            </Button>
          ) : (
            <div
              style={{
                width: '100%',
                height: '30.75px',
                display: 'flex',
                flexGrow: '1',
              }}
            />
            // <Button size="small">.</Button>
          )}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              flexGrow: '1',
            }}
          >
            <StatusIndicator
              config={{
                type: 'pulser',
                message: 'message',
                state: content.status,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselCard;
