/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
// router routes config
import routes from '../../../routes';
// MUI components
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Link,
  Slide,
} from '@mui/material';
// React Icons
import { RiMenuLine } from 'react-icons/ri';
import { IoChevronUp } from 'react-icons/io5';

// Custom module styles
import classes from './Navbar.module.css';
import { generateUUID } from '../../../utils/uuid';

const MenuItems = ({ navType, HamburgerMenuItemClickCallback }) => {
  let activeStyle = {
    textDecoration: 'none',
    borderBottom: '2px solid #1893e8',
    marginBottom: '6px',
  };

  return (
    <List
      disablePadding
      className={`${
        navType === 'MOBILE_SCREEN' ? classes.listCol : classes.listRow
      }`}
    >
      {routes.map((item) => {
        if (!item.link) {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'start',
                alignItems: 'center',
              }}
              key={generateUUID()}
            >
              <NavLink
                key={item.id}
                to={item.path}
                title={item.name}
                className={classes.navLink}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                onClick={() => {
                  if (navType === 'MOBILE_SCREEN') {
                    HamburgerMenuItemClickCallback();
                  } else {
                    //
                  }
                }}
              >
                <ListItem style={{ padding: 5 }}>
                  <ListItemButton
                    disableGutters={true}
                    className={classes.listBtn}
                    style={{ padding: 0 }}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      {item.icon}
                    </ListItemIcon>
                    {navType === 'MOBILE_SCREEN' && <span>{item.name}</span>}
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </div>
          );
        }
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'start',
              alignItems: 'center',
            }}
            key={generateUUID()}
          >
            <Link
              key={item.id}
              href={item.url}
              target="_blank"
              rel="noopener"
              style={{ textDecoration: 'none' }}
              onClick={() => {
                if (navType === 'MOBILE_SCREEN') {
                  HamburgerMenuItemClickCallback();
                } else {
                  //
                }
              }}
            >
              <ListItem
                key={item.id}
                title={item.name}
                style={{ padding: '5px 0px 5px 0px' }}
                className={classes.navLink}
              >
                <ListItemButton
                  disableGutters={true}
                  className={classes.listBtn}
                  style={{ padding: 0 }}
                >
                  <ListItemIcon
                    className={classes.listIcon}
                    style={{
                      paddingBottom: '6px',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {navType === 'MOBILE_SCREEN' && <span>{item.name}</span>}
                </ListItemButton>
              </ListItem>
            </Link>
          </div>
        );
      })}
    </List>
  );
};

const HamburgerMenu = ({ navType, handleClose }) => {
  return (
    <div className={classes.navContainer}>
      <MenuItems
        navType={navType}
        HamburgerMenuItemClickCallback={handleClose}
      />
      <hr />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          paddingBottom: '8px',
          cursor: 'pointer',
        }}
        onClick={handleClose}
        className={classes.listBtn}
      >
        <IoChevronUp size="36px" color="#1893e8" />
        <div style={{ color: '#a3a3a3' }}>Collapse</div>
      </div>
    </div>
  );
};

const NavMenu = ({ type }) => {
  const navType = type;
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const handleHamburgerMenuClick = () => {
    setShowHamburgerMenu(!showHamburgerMenu);
  };

  if (navType === 'MOBILE_SCREEN') {
    return (
      <>
        {!showHamburgerMenu && (
          <Slide
            direction="down"
            in={true}
            timeout={1000}
            mountOnEnter
            unmountOnExit
          >
            <div
              className={`${classes.navContainer} ${classes.navContainerTabletMobile}`}
            >
              <div>
                <NavLink to={'/'} title="Home">
                  <ListItemButton>
                    <ListItemIcon>{routes[0].icon}</ListItemIcon>
                  </ListItemButton>
                </NavLink>
              </div>

              <div>
                <ListItemButton
                  onClick={() => {
                    handleHamburgerMenuClick();
                  }}
                >
                  <RiMenuLine size="36px" color="#1893e8" />
                </ListItemButton>
              </div>
            </div>
          </Slide>
        )}
        {showHamburgerMenu && (
          <HamburgerMenu
            navType={navType}
            handleClose={handleHamburgerMenuClick}
          />
        )}
      </>
    );
  }

  return (
    <Slide direction="down" in={true} timeout={1500} mountOnEnter unmountOnExit>
      <div
        className={`
          ${classes.navContainer}
          ${
            navType === 'BIG_SCREEN'
              ? classes.navContainerBigScreen
              : navType === 'DESKTOP_SCREEN'
              ? classes.navContainerDesktop
              : ''
          }
        `}
      >
        <MenuItems navType={navType} />
      </div>
    </Slide>
  );
};

export default NavMenu;
