import React from 'react';
import { useScreenSize } from '../providers/ScreenSizeProvider';
import { Box } from '@mui/material';
import NavMenu from './components/NavMenu/NavMenu';

const LayoutExperience = (props) => {
  const screenSize = useScreenSize();
  const children = props.children;

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <NavMenu type={screenSize} style={{ zIndex: 9999 }} />
      {children}
    </Box>
  );
};

export default LayoutExperience;
