import React from 'react';
// MUI components
import { Fade } from '@mui/material';
// Custom components
import AppVideo from './AppScreenControls/AppVideo/AppVideo';
import AppSideBar from './AppScreenControls/AppSideBar/AppSideBar';
import AppFooter from './AppScreenControls/AppFooter/AppFooter';
// Custom module styles
import classes from '../../screensLayout.module.css';

const TikTok = (props) => {
  // console.log(props);
  return (
    <Fade in={true} timeout={3300}>
      <div className={classes.screen_content_layout}>
        <AppVideo />
        <AppSideBar />
        <AppFooter />
      </div>
    </Fade>
  );
};

export default TikTok;
