import React, { useEffect, useState } from 'react';
import moment from 'moment';
// MUI components
import { Fade } from '@mui/material';
// MUI Icons
import { SiMessenger } from 'react-icons/si';
import { TbBrandGmail } from 'react-icons/tb';
import { BsAlarmFill } from 'react-icons/bs';
import { MdOutlineSignalCellularAlt } from 'react-icons/md';
import { AiTwotoneSound } from 'react-icons/ai';
import { GiBattery50 } from 'react-icons/gi';
// Custom module styles
import classes from './deviceStatusBar.module.css';

const DeviceStatusBar = (props) => {
  const state = props.props;
  const [powerOn, setPowerOn] = useState(false);
  const [timeNow, setTimeNow] = useState(false);
  //   console.log(props.props);

  useEffect(() => {
    const getTimeNow = () => {
      const time = moment().format('h:mm');
      setTimeNow(time);
    };
    setPowerOn(state === 'Home' || state === 'TikToc' ? true : false);
    return getTimeNow();
  }, [state]);

  if (powerOn)
    return (
      <Fade in={true} timeout={2000}>
        <div className={classes.status_bar}>
          <div className={classes.status_bar_left}>
            <span className={classes.status_menu_icons}>{timeNow}</span>
            <SiMessenger />
            <TbBrandGmail />
          </div>
          <div className={classes.status_bar_right}>
            <BsAlarmFill />
            <AiTwotoneSound />
            <MdOutlineSignalCellularAlt />
            <span className={classes.status_menu_icons}>50%</span>
            <GiBattery50 />
          </div>
        </div>
      </Fade>
    );
};

export default DeviceStatusBar;
