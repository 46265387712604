import pwVars from './passwordConfig';

const generatePassword = (args, len) => {
  // console.log('generateLDPass() running', args, len);
  const sliderLength = len;
  let selections = '';
  let testCases = [];
  let pass = true;
  for (const property in args) {
    if (args[property] && pwVars) {
      if (args.similarChars) {
        selections += pwVars[property].optionsNoSimChars;
        testCases.push(pwVars[property].optionsTestCase);
      } else {
        selections += pwVars[property].options;
        testCases.push(pwVars[property].optionsTestCase);
      }
    }
  }
  if (testCases[testCases.length - 1] === '') {
    testCases.pop();
  }
  let randArr = [];
  for (let i = 0; i <= sliderLength; i++) {
    const randLetter = selections.charAt(
      Math.floor(Math.random() * selections.length)
    );
    i = randArr.push(randLetter);
  }
  randArr = randArr.join('');
  if (testCases.length > 0) {
    testCases.forEach((item, index, arr) => {
      if (item.test(randArr) === false) {
        pass = false;
      }
    });
  }

  if (!pass) {
    randArr = generatePassword(args, len);
  }
  return randArr;
};

export { generatePassword };
