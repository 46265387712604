import React from 'react';
// import Alert from '@material-ui/lab/Alert';
import { Box, Button } from '@mui/material';
import { RiGithubLine } from 'react-icons/ri';
import { RiHomeLine } from 'react-icons/ri';

const ErrorFallback = () => {
  const onResetHomeButtonClick = () => {
    window.location.href = window.location.origin;
  };
  const onNavigateGitHubButtonClick = () => {
    window.location.href = 'https://github.com/RobertStoelhorst';
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <div
        //   role="alert"
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <Alert severity="error">
        Oops! Something went wrong. We will look into the issue and fix it asap.
      </Alert> */}

        <Button
          style={{
            fontSize: '2.2em',
            fontWeight: 900,
            fontFamily: 'Oswald',
            textAlign: 'center',
          }}
          type="button"
          color="success"
          onClick={onResetHomeButtonClick}
        >
          <span style={{ marginRight: 6 }}>Return Home</span>
          <RiHomeLine size="56px" />
        </Button>
        <p
          style={{ color: 'whitesmoke', fontSize: '1.6em', margin: '1.5em 0' }}
        >
          Sorry we are experiencing some technical difficulties...
        </p>
        <Button
          style={{
            fontSize: '2.2em',
            fontWeight: 900,
            fontFamily: 'Oswald',
            textAlign: 'center',
            marginBottom: '10%',
          }}
          type="button"
          color="success"
          onClick={onNavigateGitHubButtonClick}
        >
          <span style={{ marginRight: 6 }}>Go to a safe place</span>
          <RiGithubLine size="56px" />
        </Button>
      </div>
    </Box>
  );
};

export default ErrorFallback;
