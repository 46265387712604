import YouTube from '../../../../../../assets/images/youtube-48.png';
import Spotify from '../../../../../../assets/images/spotify-48.png';
import Messenger from '../../../../../../assets/images/facebook-messenger-48.png';
import Netflix from '../../../../../../assets/images/netflix-48.png';
import Twitter from '../../../../../../assets/images/twitter-48.png';
import GooglePlay from '../../../../../../assets/images/google-play-48.png';
import Instagram from '../../../../../../assets/images/instagram-48.png';
import TikTok from '../../../../../../assets/images/tiktok-48.png';
import Phone from '../../../../../../assets/images/phone-48.png';
import Contacts from '../../../../../../assets/images/contacts-48.png';
import Camera from '../../../../../../assets/images/camera-48.png';
import Messages from '../../../../../../assets/images/messages-48.png';

const softwareConfig = [
  {
    id: 1,
    hasJourney: false,
    title: 'YouTube',
    icon: YouTube,
    loader: 'footer content here',
    extra: null,
    metadata: 'This is some text',
    height: 300,
  },
  {
    id: 2,
    hasJourney: false,
    title: 'Spotify',
    icon: Spotify,
    loader: 'footer content here',
    extra: null,
    metadata: 'This is some text',
    height: 300,
  },
  {
    id: 3,
    hasJourney: false,
    title: 'Messenger',
    icon: Messenger,
    loader: 'footer content here',
    extra: null,
    metadata: 'This is some text',
    height: 300,
  },
  {
    id: 4,
    hasJourney: false,
    title: 'Netflix',
    icon: Netflix,
    loader: 'footer content here',
    extra: null,
    metadata: 'This is some text',
    height: 300,
  },
  {
    id: 5,
    hasJourney: false,
    title: 'Twitter',
    icon: Twitter,
    loader: 'footer content here',
    extra: null,
    metadata: 'This is some text',
    height: 300,
  },
  {
    id: 6,
    hasJourney: false,
    title: 'Google Playstore',
    icon: GooglePlay,
    loader: 'footer content here',
    extra: null,
    metadata: 'This is some text',
    height: 300,
  },
  {
    id: 7,
    hasJourney: false,
    title: 'Instagram',
    icon: Instagram,
    loader: 'footer content here',
    extra: null,
    metadata: 'This is some text',
    height: 300,
  },
  {
    id: 8,
    hasJourney: true,
    title: 'TikTok',
    icon: TikTok,
    loader: 'footer content here',
    extra: null,
    metadata: 'This is some text',
    height: 300,
  },
  {
    id: 9,
    hasJourney: false,
    title: 'Phone',
    icon: Phone,
    loader: 'footer content here',
    extra: null,
    metadata: 'This is some text',
    height: 300,
  },
  {
    id: 10,
    hasJourney: false,
    title: 'Contacts',
    icon: Contacts,
    loader: 'footer content here',
    extra: null,
    metadata: 'This is some text',
    height: 300,
  },
  {
    id: 11,
    hasJourney: false,
    title: 'Camera',
    icon: Camera,
    loader: 'footer content here',
    extra: null,
    metadata: 'This is some text',
    height: 300,
  },
  {
    id: 12,
    hasJourney: false,
    title: 'Messages',
    icon: Messages,
    loader: 'footer content here',
    extra: null,
    metadata: 'This is some text',
    height: 300,
  },
];

export default softwareConfig;
