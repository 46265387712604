import React, { useState, useEffect } from 'react';
// MUI components
import { Fade, CircularProgress } from '@mui/material';
// MUI Icons
import { FcAndroidOs } from 'react-icons/fc';
// css
import classes from '../../screensLayout.module.css';

const PowerUpScreen = () => {
  const [spinner, setSpinner] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => setSpinner(<CircularProgress />), 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fade in={true} timeout={3500}>
      <div className={classes.screen_content_layout}>
        <FcAndroidOs size="6em" style={{ marginBottom: '2em' }} />
        {spinner}
      </div>
    </Fade>
  );
};

export default PowerUpScreen;
