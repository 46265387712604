import PDF from './assets/pdf/chronological-resume-robert-stoelhorst-may-2024.pdf';

import { RiHomeLine } from 'react-icons/ri';
import { RiCodeSSlashLine } from 'react-icons/ri';
// import { RiTwitterFill } from 'react-icons/ri';
import { RiLinkedinBoxFill } from 'react-icons/ri';
import { RiGithubLine } from 'react-icons/ri';
import { RiFilePdfLine } from 'react-icons/ri';
import { SiMicrosoftoutlook } from 'react-icons/si';
const navConfig = [
  {
    id: 1,
    path: '/',
    name: 'home',
    icon: <RiHomeLine size="36px" color="#1893e8" />,
    link: false,
    url: null,
  },

  {
    id: 2,
    path: '/projects',
    name: 'projects',
    icon: <RiCodeSSlashLine size="36px" color="#1893e8" />,
    link: false,
    url: null,
  },

  // {
  //   id: 3,
  //   path: '',
  //   name: 'twitter',
  //   icon: <RiTwitterFill size="36px" color="#209aee" />,
  //   link: true,
  //   url: 'https://twitter.com/RStoelhorst',
  // },

  {
    id: 4,
    path: '',
    name: 'git hub',
    icon: <RiGithubLine size="36px" />,
    link: true,
    url: 'https://github.com/RobertStoelhorst',
  },

  {
    id: 6,
    path: '',
    name: 'linkedin',
    icon: <RiLinkedinBoxFill size="36px" color="#0078d3" />,
    link: true,
    url: 'https://www.linkedin.com/in/robertstoelhorst/',
  },

  {
    id: 5,
    path: '',
    name: 'resume',
    icon: <RiFilePdfLine size="36px" />,
    link: true,
    url: PDF,
  },

  {
    id: 7,
    path: '',
    name: 'email',
    icon: <SiMicrosoftoutlook size="36px" color="#0078d3" />,
    link: true,
    url: `mailto:${encodeURIComponent('robert@robertstoelhorst.com')}`,
  },
];

export default navConfig;
