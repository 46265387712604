import React from 'react';
// Custom components
import CustomCarousel from '../Carousel/Carousel';
import projectConfig from './config';
import { useScreenSize } from '../../providers/ScreenSizeProvider';

const Projects = () => {
  const screenSize = useScreenSize();

  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CustomCarousel projects={projectConfig} screenSize={screenSize} />
    </div>
  );
};

export default Projects;
