import React from 'react';

const TikTok = () => {
  return (
    <div>
      <h1>TikTok</h1>
      <h1>TikTok</h1>
      <h1>TikTok</h1>
    </div>
  );
};

export default TikTok;
