import React, { useState } from 'react';
// MUI components
import { IconButton, Box } from '@mui/material';
// MUI Icons
import { FaPowerOff } from 'react-icons/fa';
import classes from './mobile_app.module.css';
// Custom components
import DeviceSkin from './DeviceSkin/DeviceSkin';
import DeviceExperience from './DeviceExperience/DeviceExperience';
// Custom module styles
const MobileAppProject = () => {
  const [phoneState, setPhoneState] = useState('PowerOff');
  const [selected, setSelected] = useState(false);

  // TODO refactor this is not good hardcoding
  const phonePowerHandler = () => {
    if (
      phoneState === 'PowerOn' ||
      phoneState === 'Home' ||
      phoneState === 'TikTok'
    ) {
      setPhoneState('PowerOff');
      setSelected(false);
    }
    if (phoneState === 'PowerOff') {
      setPhoneState(phoneState === 'PowerOff' ? 'PowerOn' : 'PowerOff');
      setSelected(true);
      const timer = setTimeout(() => {
        setPhoneState('Home');
      }, 4000);
      return () => clearTimeout(timer);
    }
  };

  const appLaunchHandler = (title) => {
    const state = title;
    setPhoneState(state);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 2,
      }}
    >
      <DeviceSkin state={phoneState}>
        <DeviceExperience
          phoneState={phoneState}
          appLaunch={appLaunchHandler}
        />
      </DeviceSkin>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          paddingBottom: '30%',
        }}
      >
        <div
          style={{
            borderBottom: '4px solid white',
            width: '20em',
            zIndex: -1,
          }}
        ></div>
        <IconButton
          onClick={phonePowerHandler}
          variant="contained"
          className={`${!selected ? classes.pulsatingButton : ''}`}
          sx={{
            height: '4em',
            width: '4em',
            borderRadius: '50%',
            backgroundColor: `${selected ? 'error.main' : '#7cb342'}`,
            color: 'white',
            '&:hover': {
              backgroundColor: `${selected ? '#df4848' : '#9ed265'}`,
              opacity: [0.9, 0.8, 0.8],
            },
          }}
        >
          <FaPowerOff size="80%" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default MobileAppProject;
