import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Slider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@mui/material/';

import { Autorenew, GppGood, GppMaybe, GppBad } from '@mui/icons-material';
import { generatePassword } from './passwordHelper';
import classes from './passwordGenerator.module.css';
import { useEffect } from 'react';

const PasswordGenerator = () => {
  const [password, setPassword] = useState(undefined);
  const [slider, setSlider] = useState(16);
  const [checkboxState, setCheckBoxState] = useState({
    letters: true,
    digits: true,
    symbols: false,
    similarChars: false,
  });
  const [impassableState, setImpassableState] = useState('valid');
  const { letters, digits, symbols, similarChars } = checkboxState;

  const checkBoxHandler = useCallback(() => {
    if (!slider) {
      return;
    } else {
      setPassword(generatePassword(checkboxState, slider));
    }
  }, [slider, checkboxState]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      checkBoxHandler();
    }

    return () => {
      isCancelled = true;
    };
  }, [checkBoxHandler, checkboxState, slider]);

  const handleChange = (event) => {
    setCheckBoxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
    checkBoxHandler();
  };

  const refreshPassword = () => {
    checkBoxHandler();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(password).then(
      () => {
        /* clipboard successfully set */
        alert('Copied the text: ' + password);
      },
      () => {
        /* clipboard write failed */
      }
    );
  };

  const handleSlider = (event) => {
    const len = event.target.value;
    setSlider(len);
    if (len >= 9) {
      setImpassableState('valid');
    }
    if (len <= 8 && len >= 6) {
      setImpassableState('warning');
    }
    if (len < 6) {
      setImpassableState('error');
    }
  };

  return (
    <Box
      sx={{
        pl: { xs: 2, sm: 2, md: 4, lg: 15, xl: 36 },
        pr: { xs: 2, sm: 2, md: 4, lg: 15, xl: 36 },
      }}
      className={
        impassableState === 'valid'
          ? classes.containerValid
          : impassableState === 'warning'
          ? classes.containerWarning
          : impassableState === 'error'
          ? classes.containerError
          : null
      }
    >
      <Box sx={{ pt: 18, pb: 3 }}>
        <Typography
          variant="h1"
          gutterBottom
          color="#eee"
          fontFamily="Montserrat Alternates"
          sx={{ fontSize: '1.9em', fontWeight: 600 }}
        >
          Resist hacks by using Robs password generator tool
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1.5px solid white',
        }}
      >
        <Typography variant="h6" color="#eee">
          {password}
        </Typography>

        <IconButton
          onClick={refreshPassword}
          sx={{ color: '#eee', ':hover': { color: '#09363f' } }}
        >
          <Autorenew />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 5,
        }}
      >
        {impassableState === 'valid' ? (
          <span className={classes.flxIn}>
            <GppGood sx={{ fontSize: 30, color: '#eee' }} />
            Strong password
          </span>
        ) : impassableState === 'warning' ? (
          <span className={classes.flxIn}>
            <GppMaybe sx={{ fontSize: 30, color: '#eee' }} />
            Fairly strong password
          </span>
        ) : impassableState === 'error' ? (
          <span className={classes.flxIn}>
            <GppBad sx={{ fontSize: 30, color: '#eee' }} />
            Weak password
          </span>
        ) : null}

        <Button
          variant="contained"
          size="medium"
          sx={{
            fontSize: '1em',
            fontWeight: 600,
            backgroundColor: '#eee',
            color: '#09363f',
            border: '2px solid #eee',
            ':hover': {
              backgroundColor: '#09363f',
              color: '#eee',
            },
          }}
          onClick={copyToClipboard}
        >
          Copy password
        </Button>
      </Box>

      <Box sx={{ mt: 8, mb: 2 }}>
        <Typography
          variant="body1"
          sx={{ fontSize: 15, fontWeight: 600, color: '#eee' }}
          id="sliderValue"
        >
          LENGTH ({slider})
        </Typography>
        <Slider
          controlled="false"
          min={4}
          max={40}
          value={slider}
          aria-label="sliderValue"
          valueLabelDisplay="auto"
          onChange={handleSlider}
          color="white"
          size="medium" /* this is the default size */
        />
      </Box>

      <Box sx={{}}>
        <FormControl component="fieldset" variant="standard">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              sx={{ mr: 3 }}
              control={
                <Checkbox
                  // defaultChecked
                  sx={{
                    pr: 0.5,
                    color: '#eee',
                    '&.Mui-checked': {
                      color: '#09363f',
                    },
                  }}
                  size="medium"
                  checked={letters}
                  disabled={!digits}
                  onChange={handleChange}
                  name="letters"
                />
              }
              label={
                <Typography
                  variant="caption"
                  sx={{ color: '#eee', fontWeight: 600 }}
                >
                  Letters (e.g. Aa)
                </Typography>
              }
            />
            <FormControlLabel
              sx={{ mr: 3 }}
              control={
                <Checkbox
                  sx={{
                    pr: 0.5,
                    color: '#eee',
                    '&.Mui-checked': {
                      color: '#09363f',
                    },
                  }}
                  size="medium"
                  checked={digits}
                  disabled={!letters}
                  onChange={handleChange}
                  name="digits"
                />
              }
              label={
                <Typography
                  variant="caption"
                  sx={{ color: '#eee', fontWeight: 600 }}
                >
                  Digits (e.g. 345)
                </Typography>
              }
            />
            <FormControlLabel
              sx={{ mr: 3 }}
              control={
                <Checkbox
                  sx={{
                    pr: 0.5,
                    color: '#eee',
                    '&.Mui-checked': {
                      color: '#09363f',
                    },
                  }}
                  size="medium"
                  checked={symbols}
                  onChange={handleChange}
                  name="symbols"
                />
              }
              label={
                <Typography
                  variant="caption"
                  sx={{ color: '#eee', fontWeight: 600 }}
                >
                  Symbols (@&$!#?)
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: '#eee',
                    '&.Mui-checked': {
                      color: '#09363f',
                    },
                  }}
                  size="medium"
                  checked={similarChars}
                  onChange={handleChange}
                  name="similarChars"
                />
              }
              label={
                <Typography
                  variant="caption"
                  sx={{ color: '#eee', fontWeight: 600 }}
                >
                  Excl. Similar characters (e.g. 0O1|!2Z)
                </Typography>
              }
            />
          </FormGroup>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5, pb: 3 }}>
        <Typography variant="caption" sx={{ color: '#eee', fontWeight: 600 }}>
          credit{' '}
          <a
            style={{ textDecoration: 'none' }}
            href="https://www.dashlane.com/features/password-generator"
            target="blank"
            rel="noreferrer"
          >
            Dashlane
          </a>{' '}
          for this project idea
        </Typography>
      </Box>
    </Box>
  );
};

export default PasswordGenerator;
