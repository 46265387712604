import React from 'react';

const JokesSocial = () => {
  return (
    <div>
      <h1>JokesSocial</h1>
      <h1>JokesSocial</h1>
      <h1>JokesSocial</h1>
    </div>
  );
};

export default JokesSocial;
