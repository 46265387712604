import Typography from '@mui/material/Typography';
// MUI components
import { Box, Grid } from '@mui/material';
// Custom module styles
import classes from './about.module.css';
import Profile from '../../assets/images/profile-pic-no-background-tint.png';

const AboutMe = () => {
  return (
    <Box className={classes.introContainer}>
      <Grid container justifyContent="center" direction="row-reverse">
        <Grid
          item
          xs={12}
          sm={7}
          md={7}
          lg={6}
          className={classes.aboutContainer}
        >
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              marginTop: { xs: 4, sm: 4, md: 4, lg: 0, xl: 0 },
              marginLeft: { xs: 5, sm: 0, md: 0, lg: 12, xl: 8 },
              marginRight: { xs: 5, sm: 0, md: 0, lg: 12, xl: 8 },
            }}
          >
            <Box>
              <Typography
                variant="h3"
                sx={{
                  fontSize: {
                    xs: '2rem',
                    sm: '3rem',
                  },
                }}
              >
                {'< AboutMe />'}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: '300',
                  letterSpacing: -0.7,
                  fontSize: {
                    xs: '1rem',
                    sm: '1.2rem',
                  },
                }}
              >
                In July 2020, I made a bold decision to transition from my
                previous career. Marking over twelve years of dedicated service
                within the Transport and Logistics industry. Progressing from a
                truck driver to project management, I realized that I wasn't
                fulfilling my true potential. I began on a self taught venture
                of programming while I discovered how I would become a
                professional Software Engineer, Ultimately I found myself
                unlocking my new skill while I studied Fullstack Software
                Engineering in an immersive bootcamp style setting at General
                Assembly. I am truely stimulated by the daily opportunities for
                growth and the chance to innovate each day, and I wholeheartedly
                embrace this new path.
                {/* TODO: create a learn more redirect or anchor tag to content? */}
                {/* <span
                  className="portfolio-underline-link"
                  style={{ marginLeft: '2px' }}
                  onClick={() => alert('learn more...')}
                >
                  learn more
                </span> */}
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  marginTop: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 },
                }}
              >
                <hr style={{ opacity: 0.2 }} />
                <Typography
                  variant="body2"
                  style={{ fontStyle: 'italic', letterSpacing: -0.7 }}
                >
                  "The standard you walk past is the standard you accept"
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Typography
                  variant="caption"
                  style={{ fontStyle: 'italic', letterSpacing: -0.7 }}
                >
                  David Morrison
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 },
                }}
              >
                <Typography
                  variant="body2"
                  style={{ fontStyle: 'italic', letterSpacing: -0.7 }}
                >
                  "A pessimist sees the difficulty in every opportunity; an
                  optimist sees the opportunity in every difficulty."
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Typography
                  variant="caption"
                  style={{ fontStyle: 'italic', letterSpacing: -0.7 }}
                >
                  Winston Churchill
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={7} md={7} lg={6}>
          <Box
            className={classes.imageContainer}
            sx={{
              marginTop: { xs: 4, sm: 4, md: 4, lg: 12, xl: 12 },
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <img src={Profile} height="auto" width="100%" alt="profile-pic" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutMe;
