/* eslint-disable jsx-a11y/alt-text */
import { useRef } from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
// Mui components
import { Box } from '@mui/material';
// Custom components
import MobileAppProject from '../../components/Projects/MobileAppProject/MobileAppProject';
import AboutMe from '../../components/AboutMe/AboutMe';
import Intro from '../../components/Intro/Intro';
import config from '../../constants/constants';
import { useScreenSize } from '../../providers/ScreenSizeProvider';

const Home = () => {
  const screenSize = useScreenSize();

  return <HomeHoc screenSize={screenSize} />;
};

const HomeForMobile = () => {
  return (
    <>
      <AboutMe />
    </>
  );
};

const HomeWithParallax = () => {
  const parallax = useRef();

  return (
    <Parallax ref={parallax} pages={1.47} style={{ top: '0', left: '0' }}>
      {/* Background Layer */}
      <ParallaxLayer
        speed={0}
        style={
          {
            // backgroundImage: `url(${bg})`,
            // backgroundSize: 'contain',
            // backgroundColor: '#212121',
            // backgroundColor: '#ffffff',
          }
        }
      />

      {/* AboutMe Component Layer - Moves Down */}
      <ParallaxLayer offset={0.07} speed={-5} style={{ zIndex: 1 }}>
        <AboutMe />
      </ParallaxLayer>

      {/* MobileAppProject and Intro Components Layer - Slide In from Left */}
      <ParallaxLayer
        horizontal // Slide horizontally
        offset={1.0} // Adjust offset to determine when components start sliding in
        speed={-2} // Adjust speed as needed
        style={{
          top: '32em',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <MobileAppProject />
          <Intro />
        </Box>
      </ParallaxLayer>
    </Parallax>
  );
};

const HomeHoc = ({ screenSize }) => {
  if (screenSize === config.MOBILE_SCREEN) {
    return <HomeForMobile />;
  } else {
    return <HomeWithParallax />;
  }
};

export default Home;
