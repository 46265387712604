import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
// MUI components
import { Box, CircularProgress } from '@mui/material';
// Custom components
import Home from '../pages/Home/Home';
import Projects from './Projects/Projects';
import ErrorFallback from '../error-boundary/ErrorFallback';
// Custom styles
import '../assets/styles/index.css';
import LayoutExperience from '../layouts/LayoutExperience';
import Playground from './Playground/Playground';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={40} thickness={4} />
        </div>
      </Box>
    );
  }

  return (
    <LayoutExperience>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/error" element={<ErrorFallback />} />
        <Route path="/playground" element={<Playground />} />
      </Routes>
    </LayoutExperience>
  );
};

export default App;
