import React, { useEffect, useState } from 'react';
// MUI components
import { Fade } from '@mui/material';
// MUI Icons
import { BiSquareRounded } from 'react-icons/bi';
import { HiOutlineMenu } from 'react-icons/hi';
import { FiChevronLeft } from 'react-icons/fi';
// Custom module styles
import classes from './deviceNavigation.module.css';

const DeviceNavigation = (props) => {
  const state = props.props;
  const [powerOn, setPowerOn] = useState(false);
  // console.log(state);
  // console.log(powerOn);

  useEffect(() => {
    setPowerOn(state === 'Home' || state === 'TikTok' ? true : false);
  }, [state]);

  if (powerOn)
    return (
      <Fade in={true} timeout={2000}>
        <div className={classes.menu_bar}>
          <HiOutlineMenu size="28px" className={classes.menu_button} />
          <BiSquareRounded size="28px" />
          <FiChevronLeft size="32px" />
        </div>
      </Fade>
    );
};

export default DeviceNavigation;
