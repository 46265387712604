import React from 'react';
import { Button } from '@mui/material';
import { RiGithubLine } from 'react-icons/ri';

const ProjectStateExperience = (props) => {
  const { project } = props;
  // const { reason } = props;
  // console.log('reason', reason);
  const onNavigateGitHubButtonClick = () => {
    window.open('https://github.com/RobertStoelhorst', '_blank');
  };

  return (
    <div style={styles.container}>
      <div style={styles.component}>
        <p
          style={{ color: 'whitesmoke', fontSize: '1.6em', margin: '1.5em 0' }}
        >
          I am currently working on a project hosting solution for {project}.
        </p>
        <p
          style={{ color: 'whitesmoke', fontSize: '1.6em', margin: '1.5em 0' }}
        >
          For now you can go back and checkout my other projects or head over
          and take a look at the source code in my Github.
        </p>
        <p
          style={{ color: 'whitesmoke', fontSize: '1.6em', margin: '1.5em 0' }}
        >
          you can also download a copy of my resume above.
        </p>
        <Button
          style={{
            fontSize: '2.2em',
            fontWeight: 900,
            fontFamily: 'Oswald',
            textAlign: 'center',
            marginBottom: '10%',
          }}
          type="button"
          color="success"
          onClick={onNavigateGitHubButtonClick}
        >
          <span style={{ marginRight: 6 }}>Github</span>
          <RiGithubLine size="56px" />
        </Button>
      </div>
    </div>
  );
};

export default ProjectStateExperience;

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: '100vh',
    backgroundColor: 'rgb(48, 48, 48)',
  },
  component: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
  },
};
