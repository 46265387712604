// MUI components
import { Box, Typography } from '@mui/material';
import moment from 'moment';

// function to dynamically calculate the amount of time represented in years since a given date
const yearsSinceFixedDate = (fixedDate) => {
  const currentDate = moment();
  const yearsSince = currentDate.diff(fixedDate, 'years', true);
  return yearsSince.toFixed(0);
};

const Intro = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: '300',
          letterSpacing: -0.7,
          color: '#626262',
          fontSize: {
            xs: '1rem',
            sm: '1.3rem',
          },
        }}
      >
        My name is Robert &#40;Rob&#41; Stoelhorst &amp; I create experiences on
        the web I am a Fullstack focused software engineer with{' '}
        {yearsSinceFixedDate('2020-07-07')} years of product delivery
        experience, Specializing in React frontend development and driven by my
        curiosity to discover and learn backend frameworks and programming
        languages powered and dynamically served by cloud infrastructure.
      </Typography>
    </Box>
  );
};

export default Intro;
