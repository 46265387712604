/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Fade, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CarouselCard from '../Carousel/CarouselCard';
import { LeftArrow, RightArrow } from '../CustomButtons/CustomButtons';
// import classes from './Carousel.module.css';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import './hideScrollbar.css';
import config from '../../constants/constants';
import ProjectStateExperience from '../Projects/ProjectStateExperience';

const Project = ({
  children,
  selected,
  screenSize,
  disabled,
  onClickCallback,
}) => {
  return (
    <div
      disabled={disabled}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 5 }}
      >
        <IconButton
          onClick={onClickCallback}
          sx={{
            position: 'relative',
            ':hover': { color: '#09363f', backgroundColor: 'transparent' },
          }}
        >
          <CloseIcon />
          <span style={{ fontSize: 13 }}>EXIT</span>
        </IconButton>
      </div>
      <>{children}</>
    </div>
  );
};

const CustomCarousel = ({ projects, screenSize }) => {
  const [items, setItems] = useState(projects);
  const [selected, setSelected] = useState(undefined);
  const [project, setProject] = useState(undefined);

  const styles = {
    component: {
      position: 'relative',
      width: screenSize === config.MOBILE_SCREEN ? '100%' : '80%',
    },
  };

  const handleCardClick = (item, itemComponent) => () => {
    if (!item.hasAction) {
      return;
    } else if (item.status === 'WARNING' || item.status === 'DOWN') {
      setSelected(item);
      setProject(
        <ProjectStateExperience
          project={item.title}
          reason={item.status_reason}
        />
      );
    } else {
      setSelected(item);
      setProject(itemComponent);
    }
  };

  const resetSelectedCardState = () => {
    setSelected(undefined);
    setProject(undefined);
  };

  const onWheel = (apiObj, ev) => {
    const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isTouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollPrev();
    } else if (ev.deltaY > 0) {
      apiObj.scrollNext();
    }
  };
  if (!selected) {
    return (
      <Fade in={true} timeout={1000}>
        <div style={styles.component}>
          <ScrollMenu
            LeftArrow={<LeftArrow />}
            RightArrow={<RightArrow />}
            onWheel={onWheel}
          >
            {items.map((i) => (
              <CarouselCard
                title={i.title}
                itemId={i.id} // NOTE: itemId is required for tracking items
                key={i.id}
                onClick={handleCardClick(i, i.component)}
                // selected={isItemSelected(i.id)}
                selected={i.id && i.component === selected}
                content={i}
                size={
                  screenSize === config.MOBILE_SCREEN
                    ? 'sm'
                    : screenSize === config.DESKTOP_SCREEN
                    ? 'lg'
                    : screenSize === config.BIG_SCREEN
                    ? 'xl'
                    : null
                }
              />
            ))}
          </ScrollMenu>
        </div>
      </Fade>
    );
  }
  return (
    <div style={{ width: '100%' }}>
      <Project
        selected={selected}
        screenSize={screenSize}
        onClickCallback={resetSelectedCardState}
      >
        {project}
      </Project>
    </div>
  );
};

export default CustomCarousel;
