/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Slide,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
} from '@mui/material';

const SlideDownUI = () => {
  const [slideOpen, setSlideOpen] = useState(false);

  const handleSlideToggle = () => {
    setSlideOpen(!slideOpen);
  };

  return (
    <div style={{ paddingTop: slideOpen ? '100px' : '64px' }}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6">Your App Name</Typography>
          <Button color="inherit" onClick={handleSlideToggle}>
            Toggle Slide
          </Button>
        </Toolbar>
      </AppBar>

      <Container>
        {/* Main content */}
        <Typography
          variant="h3"
          // style={{ marginTop: '100px' }}
        >
          Main Content Below Slide
        </Typography>
        <Typography variant="body1">
          This is the main content that should move down when the slide is open.
        </Typography>
      </Container>

      <Slide direction="down" in={slideOpen} mountOnEnter unmountOnExit>
        <Container>
          {/* Slide content */}
          <Typography
            variant="h5"
            // style={{ marginTop: '10px' }}
          >
            Slide Content
          </Typography>
          <Typography variant="body1">
            This is the content that slides down from the top of the screen.
          </Typography>
        </Container>
      </Slide>
    </div>
  );
};

const CustomStyleCode = () => {
  return (
    <div style={{ marginLeft: 20 }}>
      <span
        className="portfolio-underline-link"
        style={{ marginLeft: '2px' }}
        onClick={() => alert('learn more...')}
      >
        learn more
      </span>
    </div>
  );
};

const Playground = () => {
  return <SlideDownUI />;
  // return <CustomStyleCode />;
};

export default Playground;
