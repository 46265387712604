import React from 'react';

const Header = () => {
  return (
    <div className="header">
      <h1>EasySave</h1>
      <h4>Take control of your finances</h4>
    </div>
  );
};

export default Header;
