const params = {
  letters: {
    options: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    optionsTestCase: /[a-zA-Z]/,
    optionsNoSimChars: 'ABCDEFGHJKLMNPQRSTUVWXYabcdefghjkmnpqrstuvwxy',
  },
  digits: {
    options: '0123456789',
    optionsTestCase: /[0-9]/,
    optionsNoSimChars: '3456789',
  },
  symbols: {
    options: '!@#$%^&*_+{}=-|?/><',
    optionsTestCase: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
    optionsNoSimChars: '@#$%^&*_+{}=-?/><',
  },
  similarChars: {
    options: '',
    optionsTestCase: '',
    optionsNoSimChars: '',
  },
};

export default params;
